<template>
  <div v-if="profile.is_delegate_mode" class="bg-secondary py-2 px-6 z-[99] flex items-center justify-between text-sm text-primary">
    <div class="hidden 2xl:block xl:w-3/12"></div>
    <div class="2xl:w-3/12">
      <p><span class="font-semibold">Delegate access mode</span> - some features may not be available</p>
    </div>
    <div class="xl:w-6/12 2xl:w-3/12 flex items-center justify-end gap-12">
      <p>Logged in as: {{account.name}}</p>
      <button @click="exitDelegateMode" class="font-semibold flex gap-1 items-center after:content-['\D7'] after:text-xl">Exit access</button>
    </div>
  </div>
</template>

<script setup lang="ts">

import { useUserStore } from "@/stores/user";
import { useDelegates } from "@/composables/useDelegates";

const {profile, account} = useUserStore();
const {exitDelegateMode} = useDelegates();

</script>
